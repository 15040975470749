import styled from "styled-components";
import { PolymorphicComponent } from "../../util/polymorphic";
import Button from "./Button";
import { BaseButtonProps } from "./types";

const IconButton: PolymorphicComponent<BaseButtonProps, "button"> = styled(Button)<BaseButtonProps>`
  padding: 0;
  width: ${({ width, scale }) => (width || scale === "sm" ? "32px" : "48px")};

  &:hover {
    opacity: ${({ disabled }) => (disabled ? 1 : 0.6)};
  }
`;

export default IconButton;
