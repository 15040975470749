import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M12 0C5.36705 0 0 5.36752 0 12C0 18.633 5.36752 24 12 24C18.633 24 24 18.6325 24 12C24 5.36705 18.6324 0 12 0ZM12 22.125C6.40345 22.125 1.875 17.5962 1.875 12C1.875 6.40345 6.40383 1.875 12 1.875C17.5965 1.875 22.125 6.40383 22.125 12C22.125 17.5965 17.5961 22.125 12 22.125Z" />
      <path d="M12.4688 6.04102H9.1875C8.66972 6.04102 8.25 6.46073 8.25 6.97852C8.25 7.4963 8.66972 7.91602 9.1875 7.91602H12.4688C13.2442 7.91602 13.875 8.54686 13.875 9.32226C13.875 10.0977 13.2442 10.7285 12.4688 10.7285H11.5312C11.0135 10.7285 10.5938 11.1482 10.5938 11.666V13.0156C10.5938 13.5334 11.0135 13.9531 11.5312 13.9531C12.049 13.9531 12.4688 13.5334 12.4688 13.0156V12.6035C14.278 12.6035 15.75 11.1315 15.75 9.32226C15.75 7.51298 14.278 6.04102 12.4688 6.04102Z" />
      <path d="M11.5312 17.6328C12.2302 17.6328 12.7969 17.0662 12.7969 16.3672C12.7969 15.6682 12.2302 15.1016 11.5312 15.1016C10.8323 15.1016 10.2656 15.6682 10.2656 16.3672C10.2656 17.0662 10.8323 17.6328 11.5312 17.6328Z" />
    </Svg>
  );
};

export default Icon;
