import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 30 18" {...props}>
      <path d="M0.668771 10.3228C0.669114 10.3232 0.6694 10.3237 0.669802 10.3241L6.65701 17.5929C7.10554 18.1375 7.83103 18.1355 8.2775 17.5882C8.72392 17.041 8.7222 16.156 8.27366 15.6114L4.25385 10.7312L28.5202 10.7312C29.153 10.7312 29.666 10.1054 29.666 9.33333C29.666 8.5613 29.153 7.93548 28.5202 7.93548L4.25391 7.93548L8.27361 3.05531C8.72214 2.51071 8.72386 1.62566 8.27744 1.07847C7.83097 0.531141 7.10543 0.529255 6.65695 1.07372L0.669743 8.34254C0.6694 8.34296 0.669114 8.34344 0.668713 8.34386C0.219947 8.89028 0.221379 9.7782 0.668771 10.3228Z" />
    </Svg>
  );
};

export default Icon;
