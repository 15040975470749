import React from "react";
import styled from "styled-components";
import { Variant, variants } from "./types";
import { Image } from "../../../../components/Image";
import { WaitFilled, WalletIcon, WarningFilled, ErrorFilled } from "../../../../components/Svg";

const MenuIconWrapper = styled.div`
  align-items: center;
  display: flex;
  height: auto;
  justify-content: center;
  left: -4px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  z-index: 102;
`;

const ProfileIcon = styled(Image)`
  left: 0;
  position: absolute;
  top: -4px;
  z-index: 102;

  & > img {
    border-radius: 50%;
  }
`;

export const NoProfileMenuIcon: React.FC = () => (
  <MenuIconWrapper>
    <WalletIcon color="white" width="24px" />
  </MenuIconWrapper>
);

export const PendingMenuIcon: React.FC = () => (
  <MenuIconWrapper>
    <WaitFilled color="secondary" width="24px" spin />
  </MenuIconWrapper>
);

export const WarningMenuIcon: React.FC = () => (
  <MenuIconWrapper>
    <ErrorFilled color="warning" width="24px" />
  </MenuIconWrapper>
);

export const DangerMenuIcon: React.FC = () => (
  <MenuIconWrapper>
    <WarningFilled color="failure" width="24px" />
  </MenuIconWrapper>
);

const MenuIcon: React.FC<{ avatarSrc?: string; variant: Variant }> = ({ avatarSrc, variant }) => {
  if (variant === variants.DANGER) {
    return <DangerMenuIcon />;
  }

  if (variant === variants.WARNING) {
    return <WarningMenuIcon />;
  }

  if (variant === variants.PENDING) {
    return <PendingMenuIcon />;
  }

  if (!avatarSrc) {
    return <NoProfileMenuIcon />;
  }

  return <ProfileIcon src={avatarSrc} height={40} width={40} />;
};

export default MenuIcon;
