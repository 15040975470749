import React, { useState } from "react";
import styled from "styled-components";
import getExternalLinkProps from "../../util/getExternalLinkProps";
import Grid from "../../components/Box/Grid";
import Box from "../../components/Box/Box";
import Flex from "../../components/Box/Flex";
import Text from "../../components/Text/Text";
import Heading from "../../components/Heading/Heading";
import { Button } from "../../components/Button";
import { ModalBody, ModalCloseButton, ModalContainer, ModalHeader, ModalTitle } from "../Modal";
import WalletCard from "./WalletCard";
import config, { walletLocalStorageKey } from "./config";
import { Config, Login } from "./types";
import { useMatchBreakpoints } from "../../hooks";

interface Props {
  login: Login;
  onDismiss?: () => void;
  displayCount?: number;
  t: (key: string) => string;
}

const More = styled(Flex)`
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
`;

const ModalContent = styled(ModalBody)`
  padding: 0;
  margin: 0 auto 0;
  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0 40px;
  }
`;

/**
 * Checks local storage if we have saved the last wallet the user connected with
 * If we find something we put it at the top of the list
 *
 * @returns sorted config
 */
const getPreferredConfig = (walletConfig: Config[]) => {
  const sortedConfig = walletConfig.sort((a: Config, b: Config) => a.priority - b.priority);

  const preferredWalletName = localStorage?.getItem(walletLocalStorageKey);

  if (!preferredWalletName) {
    return sortedConfig;
  }

  const preferredWallet = sortedConfig.find((sortedWalletConfig) => sortedWalletConfig.title === preferredWalletName);

  if (!preferredWallet) {
    return sortedConfig;
  }

  return [
    preferredWallet,
    ...sortedConfig.filter((sortedWalletConfig) => sortedWalletConfig.title !== preferredWalletName),
  ];
};

const ConnectModal: React.FC<Props> = ({ login, onDismiss = () => null, displayCount = 3, t }) => {
  const [showMore, setShowMore] = useState(false);
  const { isMobile } = useMatchBreakpoints();

  const sortedConfig = getPreferredConfig(config);
  // Filter out WalletConnect if user is inside TrustWallet built-in browser
  const walletsToShow = window.ethereum?.isTrust
    ? sortedConfig.filter((wallet) => wallet.title !== "WalletConnect")
    : sortedConfig;
  const displayListConfig = showMore ? walletsToShow : walletsToShow.slice(0, displayCount);

  return (
    <ModalContainer minWidth="320px">
      <ModalHeader>
        <ModalTitle>
          <Heading scale={isMobile ? "md" : "xl"}>{t("Connect Wallet")}</Heading>
        </ModalTitle>
        <ModalCloseButton onDismiss={onDismiss} />
      </ModalHeader>
      <ModalContent width={["100%", null, "628px"]}>
        <Box pb="32px" maxHeight="472px" overflowY="auto">
          <Grid gridTemplateColumns={["1fr 1fr", "1fr 1fr", "1fr 1fr 1fr"]} gridGap={32}>
            {displayListConfig.map((wallet, index) => (
              <Grid
                key={wallet.title}
                gridColumn={["auto", "auto", index === sortedConfig?.length - 1 ? "span 3" : "auto"]}
              >
                <WalletCard walletConfig={wallet} login={login} onDismiss={onDismiss} />
              </Grid>
            ))}
          </Grid>
        </Box>
        <More>
          {!showMore && (
            <Button
              scale="md"
              mb="32px"
              variant="secondary"
              style={{ width: "216px" }}
              onClick={() => setShowMore(true)}
            >
              {t("More")}
            </Button>
          )}
        </More>
        <Flex p="32px" justifyContent="center" flexDirection="column" alignItems="center">
          <Text textAlign="center" weight={500} color="text" as="p" mb="24px">
            {t("Haven’t got a crypto wallet yet?")}
          </Text>
          <Button as="a" style={{ width: "256px" }} href="#" size="lg" variant="primary" {...getExternalLinkProps()}>
            {t("Learn How to Connect")}
          </Button>
        </Flex>
      </ModalContent>
    </ModalContainer>
  );
};

export default ConnectModal;
