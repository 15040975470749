import React from "react";
import styled, { DefaultTheme } from "styled-components";
import CheckmarkCircleFill from "../Svg/Icons/CheckmarkCircleFill";
import ErrorFilled from "../Svg/Icons/ErrorFilled";
import WarningFilled from "../Svg/Icons/WarningFilled";
import InfoFilled from "../Svg/Icons/InfoFilled";
import { Text } from "../Text";
import { IconButton } from "../Button";
import { CloseIcon } from "../Svg";
import Flex from "../Box/Flex";
import { AlertProps, variants } from "./types";

interface ThemedIconLabel {
  variant: AlertProps["variant"];
  theme: DefaultTheme;
  hasDescription: boolean;
}

const getThemeColor = ({ theme, variant = variants.INFO }: ThemedIconLabel) => {
  switch (variant) {
    case variants.DANGER:
      return theme.colors.failure;
    case variants.WARNING:
      return theme.colors.warning;
    case variants.SUCCESS:
      return theme.colors.success;
    case variants.INFO:
    default:
      return theme.colors.primary;
  }
};

const getIcon = (variant: AlertProps["variant"] = variants.INFO) => {
  switch (variant) {
    case variants.DANGER:
      return WarningFilled;
    case variants.WARNING:
      return ErrorFilled;
    case variants.SUCCESS:
      return CheckmarkCircleFill;
    case variants.INFO:
    default:
      return InfoFilled;
  }
};

const IconLabel = styled.div<ThemedIconLabel>`
  border-radius: 16px 0 0 16px;
  color: ${getThemeColor};
  padding: 24px 16px;
`;

const withHandlerSpacing = 32 + 12 + 8; // button size + inner spacing + handler position
const Details = styled.div<{ hasHandler: boolean }>`
  flex: 1;
  padding-bottom: 24px;
  padding-left: 0;
  padding-right: ${({ hasHandler }) => (hasHandler ? `${withHandlerSpacing}px` : "16px")};
  padding-top: 24px;
`;

const CloseHandler = styled.div`
  border-radius: 0 16px 16px 0;
  right: 14px;
  position: absolute;
  top: 14px;
`;

const AlertText = styled(Text).attrs({ bold: true })<ThemedIconLabel>`
  color: ${getThemeColor};
  font-size: 20px;
  line-height: 23px;
  padding-bottom: ${({ hasDescription }) => `${hasDescription ? 8 : 0}px`};
`;

const StyledAlert = styled(Flex)`
  position: relative;
  background-color: ${({ theme }) => theme.alert.background};
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.shadows.default};
`;

const Alert: React.FC<AlertProps> = ({ title, children, variant, onClick }) => {
  const Icon = getIcon(variant);

  return (
    <StyledAlert>
      <IconLabel variant={variant} hasDescription={!!children}>
        <Icon color="currentColor" width="24px" />
      </IconLabel>
      <Details hasHandler={!!onClick}>
        <AlertText variant={variant} hasDescription={!!children}>
          {title}
        </AlertText>
        {typeof children === "string" ? <Text as="p">{children}</Text> : children}
      </Details>
      {onClick && (
        <CloseHandler>
          <IconButton scale="sm" variant="text" onClick={onClick}>
            <CloseIcon width="16px" color="textSubtle" />
          </IconButton>
        </CloseHandler>
      )}
    </StyledAlert>
  );
};

export default Alert;
