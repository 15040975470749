import { scales, buttonVariants } from "./types";

const HOVER = ":hover:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled):not(:active)";

const ACTIVE = ":active:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled)";

export const styleVariants = {
  [buttonVariants.PRIMARY]: {
    backgroundColor: "primary",
    color: "white",
    border: "2px solid",
    borderColor: "primary",

    [HOVER]: {
      backgroundColor: "primaryMedium",
      borderColor: "primaryMedium",
    },

    [ACTIVE]: {
      backgroundColor: "primaryDark",
      borderColor: "primaryDark",
    },
  },
  [buttonVariants.PRIMARY_LIGHT]: {
    backgroundColor: "primaryLight",
    borderColor: "primaryLight",
    color: "primary",
    boxShadow: "none",

    [HOVER]: {
      backgroundColor: "primaryMedium",
      borderColor: "primaryMedium",
      color: "white",
    },

    [ACTIVE]: {
      backgroundColor: "primaryDark",
      borderColor: "primaryDark",
      color: "white",
    },
  },
  [buttonVariants.SECONDARY]: {
    backgroundColor: "transparent",
    border: "2px solid",
    borderColor: "primary",
    boxShadow: "none",
    color: "primary",

    [HOVER]: {
      color: "primaryMedium",
      borderColor: "primaryMedium",
    },

    [ACTIVE]: {
      color: "primaryDark",
      borderColor: "primaryDark",
    },

    ":disabled": {
      color: "disabled",
      backgroundColor: "transparent",
    },
  },
  [buttonVariants.TERTIARY]: {
    backgroundColor: "transparent",
    boxShadow: "none",
    color: "primary",
    [HOVER]: {
      color: "primaryMedium",
    },

    [ACTIVE]: {
      color: "primaryDark",
    },

    ":disabled": {
      color: "disabled",
    },
  },
  [buttonVariants.SUBTLE]: {
    backgroundColor: "textSubtle",
    color: "backgroundAlt",
  },
  [buttonVariants.DANGER]: {
    backgroundColor: "failure",
    color: "white",
  },
  [buttonVariants.WARNING]: {
    backgroundColor: "warning",
    color: "white",
  },
  [buttonVariants.SUCCESS]: {
    backgroundColor: "success",
    color: "white",
  },
  [buttonVariants.TEXT]: {
    backgroundColor: "transparent",
    color: "primary",
    boxShadow: "none",

    ":disabled": {
      backgroundColor: "transparent",
      color: "primary",
      boxShadow: "none",
    },
  },
  [buttonVariants.LIGHT]: {
    backgroundColor: "white",
    color: "text",
    boxShadow: "none",

    [HOVER]: {
      backgroundColor: "textSubtle",
    },
  },
};

export const scaleVariants = {
  [scales.LG]: {
    height: "56px",
    padding: "0 36px",
  },
  [scales.MD]: {
    height: "40px",
    padding: "0 36px",
  },
  [scales["2SM"]]: {
    height: "48px",
    padding: "0 36px",
  },
  [scales.SM]: {
    height: "33px",
    padding: "0 16px",
  },
  [scales.XS]: {
    height: "24px",
    fontSize: "12px",
    padding: "0 14px",
  },
};
