import styled from "styled-components";
import { darkColors, lightColors } from "../../theme/colors";
import { Flex } from "../Box";
import SocialLinks from "./Components/SocialLinks";

export const StyledFooter = styled(Flex)`
  background: ${lightColors.text};
`;

export const StyledList = styled.ul`
  list-style: none;

  ${({ theme }) => theme.mediaQueries.md} {
    margin-bottom: 0;
  }
`;

export const StyledListItem = styled.li`
  font-size: 14px;
  line-height: 32px;
  margin-bottom: 10px;
  text-transform: capitalize;
  opacity: 0.8;

  &:first-child {
    color: ${lightColors.white};
    font-weight: 700;
    margin-bottom: 16px;
    text-transform: uppercase;
    opacity: 1;
  }
`;

export const StyledToolsContainer = styled(Flex)`
  border-color: ${darkColors.cardBorder};
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-style: solid;
  padding: 24px 0;
  margin-bottom: 24px;

  ${({ theme }) => theme.mediaQueries.sm} {
    border-top-width: 0;
    border-bottom-width: 0;
    padding: 0 0;
    margin-bottom: 0;
  }
`;

export const StyledSocialLinks = styled(SocialLinks)`
  justify-content: flex-start;
  ${({ theme }) => theme.mediaQueries.sm} {
    justify-content: flex-end;
  }
  border-bottom: 1px solid ${darkColors.cardBorder};
`;

export const StyledText = styled.span`
  color: ${darkColors.text};
`;
