const variants = {
  warning: {
    background: "rgba(255, 168, 0, 0.15)",
    borderColor: "warning",
  },
  danger: {
    background: "rgba(255, 60, 42, 0.15)",
    borderColor: "failure",
  },
  success: {
    background: "rgba(46, 212, 122, 0.15)",
    borderColor: "success",
  },
};

export default variants;
