import { MenuItemsType, SwapIcon, SwapFillIcon, EarnFillIcon, EarnIcon } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean; background?: string }

const config: (t: ContextApi['t'], languageCode?: string) => ConfigMenuItemsType[] = (t) => [
  {
    label: t('Trade'),
    icon: SwapIcon,
    fillIcon: SwapFillIcon,
    href: '/swap',
    showItemsOnMobile: false,
    items: [
      {
        label: t('Swap'),
        href: '/swap',
      },
      // {
      //   label: t('Limit'),
      //   href: '/limit-orders',
      // },
      {
        label: t('Liquidity'),
        href: '/liquidity',
      },
    ],
  },
  {
    label: t('Earn'),
    href: '/farms',
    background: 'white',
    icon: EarnIcon,
    fillIcon: EarnFillIcon,
    items: [
      {
        label: t('Farms'),
        href: '/farms',
      },
      {
        label: t('Pools'),
        href: '/pools',
      },
    ],
  },
  {
    label: t('Info'),
    href: '/info',
    icon: EarnIcon,
    fillIcon: EarnFillIcon,
    hideSubNav: true,
    items: [
      {
        label: t('Overview'),
        href: '/info',
      },
      {
        label: t('Pools'),
        href: '/info/pools',
      },

      {
        label: t('Tokens'),
        href: '/info/tokens',
      },
    ],
  },
]

export default config
