import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import getExternalLinkProps from "../../util/getExternalLinkProps";
import Text from "../Text/Text";
import { LinkProps } from "./types";

const StyledLink = styled(Text)<LinkProps>`
  display: flex;
  align-items: center;
  width: fit-content;
  text-decoration: ${({ underline }) => (underline ? "underline" : "none")};
  &:hover {
    text-decoration: underline;
  }
`;

const Link: React.FC<LinkProps> = ({ external, ...props }) => {
  const internalProps = external ? getExternalLinkProps() : {};
  return <StyledLink fontSize="14px" as="a" bold {...internalProps} {...props} />;
};

Link.propTypes = {
  color: PropTypes.string,
};

Link.defaultProps = {
  color: "primary",
};

export default Link;
