import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

interface LogoProps extends SvgProps {
  isDark: boolean;
  id: string;
}

const Logo: React.FC<LogoProps> = ({ id, isDark, ...props }) => {
  const fillId = `url(#${id})`;
  const textColor = isDark ? "#FFFFFF" : "#FFFFFF";
  return (
    <Svg id="logo" viewBox="0 0 1920 800" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient id={id} x1="305.67" y1="681.46" x2="141.17" y2="274.12" gradientUnits="userSpaceOnUse">
          <stop offset="0.17" stopColor="#2c89ff" />
          <stop offset="1" stopColor="#70fedc" />
        </linearGradient>
        <linearGradient id="Безымянный_градиент_10-2" x1="232.56" y1="710.98" x2="68.06" y2="303.65" />
        <linearGradient id="Безымянный_градиент_10-3" x1="165.03" y1="738.26" x2="0.53" y2="330.92" />
        <linearGradient id="Безымянный_градиент_10-4" x1="196.37" y1="725.6" x2="31.86" y2="318.26" />
        <linearGradient id="Безымянный_градиент_10-5" x1="346.73" y1="664.88" x2="182.23" y2="257.54" />
        <linearGradient id="Безымянный_градиент_10-6" x1="287.59" y1="688.76" x2="123.09" y2="281.42" />
      </defs>
      <path
        className="cls-1"
        d="M367.75,400.12c-2-2.24-4.73-2.79-7.49-3.4-24.79-5.46-48.09-14.83-70.5-26.63a14.44,14.44,0,0,1-7.06-8c-1.89-4.79-3.29-9.63-4.69-14h0l0-.07c-1.56-4.9-3.16-9.19-5.44-12.09Q253,292.47,233.41,249s-11.85-29.66-41.64-84.8c-4.14-7.65-9.94-8.46-15.92-2.23q-21.27,22.17-42.4,44.46l-7.79,8.2.67,1L162.1,202c-.28,1.09-.89,3.37-1.68,6.22-6.86,24.77-36.83,124.61-40.87,136.86a6.36,6.36,0,0,0,0,4.76,6.67,6.67,0,0,0,4.09,3c56.83,18.17,69.89,19.11,70,19.11h0c16.16,2.2,32.33,3.69,48.66,2.86A249.37,249.37,0,0,0,269,371.53c-.5.85-.6,1.29-.85,1.41a38.38,38.38,0,0,1-3.86,1.62c-50,16.76-99.41,15.41-148.26-4.4-3.32-1.34-6-1.61-9.41-.24-14.27,5.77-28.72,11.1-43.42,15.7a297.32,297.32,0,0,1-36.1,9.18c-5.06.91-8.29,3.62-8.87,8.89-.55,5,2.68,7.19,6.7,8.77,1.46.58,2.87,1.29,4.33,1.88q127,51.3,261.12,23.64a372.23,372.23,0,0,0,73.37-23.49C370.36,411.62,372.12,405,367.75,400.12Z"
        fill={fillId}
      />
      <path
        className="cls-2"
        d="M208,617.66h-.14c0-15-.5-29.95.17-44.89.48-10.76-5.79-10.94-13.43-10.78s-14.66-.94-14.38,10.81c.62,26.09.17,52.22.17,78.33,0,19.93,0,19.77,20,18.55,5.59-.34,7.84-2.23,7.71-8.08C207.78,647,208,632.31,208,617.66Z"
        fill={fillId}
      />
      <path
        className="cls-3"
        d="M73.66,525.7v78.63a30.5,30.5,0,0,0,30.51-30.5V495.2A30.51,30.51,0,0,0,73.66,525.7Z"
        fill={fillId}
      />
      <path
        className="cls-4"
        d="M126.27,565.74v78.64a30.51,30.51,0,0,0,30.51-30.51V535.24A30.51,30.51,0,0,0,126.27,565.74Z"
        fill={fillId}
      />
      <path
        className="cls-5"
        d="M285,495.2h0v78.63a30.5,30.5,0,0,0,30.5,30.5V525.7A30.5,30.5,0,0,0,285,495.2Z"
        fill={fillId}
      />
      <path
        className="cls-6"
        d="M232.38,535.24v78.63a30.5,30.5,0,0,0,30.5,30.51V565.74A30.51,30.51,0,0,0,232.38,535.24Z"
        fill={fillId}
      />
      <path
        className="cls-7"
        d="M461.59,507.83V341.12h36.58l48,96.54,15.18,34.59h2L578,437.66l47.52-96.54h36.58V507.83H635.27V405.57l1-24.14h-2l-10.2,24.14-46,92.31H545.69l-46-92.31-10.2-24.14h-2l1,24.14V507.83Z"
        fill={textColor}
      />
      <path
        className="cls-7"
        d="M681.54,478c0-19.16,15.42-29.11,39.81-31.85l56.23-6v-7.21c0-20.41-9-27.62-32.59-27.62-22.9,0-34.09,7.46-34.09,25.62v1H684.27v-1c0-28.11,23.39-49.26,62.71-49.26s57,21.4,57,51v75.14H779.32V477h-1.74c-6.47,20.9-26.13,33.34-54.24,33.34C697.71,510.32,681.54,498.37,681.54,478Zm49,12.94c28.12,0,47-10.45,47-34.84l-48.52,5.48c-13.93,1.74-20.65,5-20.65,14.68C708.41,486.18,715.13,490.91,730.55,490.91Z"
        fill={textColor}
      />
      <path
        className="cls-7"
        d="M822.86,439.16c0-34.34,22.89-57.48,60.46-57.48,25.88,0,45.29,11.69,49.77,33.34h1.49V384.17h24.63V493.4c0,41.55-25.88,59.22-66.43,59.22-36.58,0-62-17.17-62-47h26.62c0,17.91,10.45,24.63,36.83,24.63,28.12,0,38.32-7.71,38.32-35.33V464.54h-1.74c-4.48,18.66-21.15,32.09-49.27,32.09C844.75,496.63,822.86,473.49,822.86,439.16Zm67.43,34.33c27.37,0,42.3-11.44,42.3-33.34v-3.48c0-20.9-15.43-31.6-41.56-31.6-25.87,0-41,8.71-41,34.09S864.91,473.49,890.29,473.49Z"
        fill={textColor}
      />
      <path className="cls-7" d="M984.08,369.74V341.12H1011v28.62Zm0,138.09V384.17H1011V507.83Z" fill={textColor} />
      <path
        className="cls-7"
        d="M1030.86,446.12c0-38.56,26.62-64.44,67.43-64.44,37.07,0,64.19,20.9,64.19,51.75v2.49h-26.62v-1.49c0-19.16-13.93-28.62-38.07-28.62-27.87,0-40.31,13.19-40.31,40.31,0,26.63,12.44,40.06,40.31,40.06,24.14,0,38.07-9.45,38.07-28.61v-1.74h26.62v2.73c0,30.86-27.12,51.76-64.19,51.76C1057.48,510.32,1030.86,484.19,1030.86,446.12Z"
        fill={textColor}
      />
      <path
        className="cls-7"
        d="M1219.7,453.34v-1.49h26.87v4c0,19.9,11.69,29.85,48.27,29.85,31.85,0,43.05-7,43.05-22.64,0-14.43-8.46-19.9-29.61-23.88l-40.81-6.72c-28.12-5-48-17.92-48-45.29,0-24.13,19.9-48.52,71.66-48.52,48.77,0,70.91,24.39,70.91,57v1.5H1335.4v-3.24c0-20.4-12.19-30.6-46.78-30.6-30.36,0-42.3,7.46-42.3,22.39,0,14.43,8.46,19.16,28.12,23.39l40.56,7c33.34,6,49.76,21.14,49.76,45.53,0,25.38-20.65,48.77-69.92,48.77C1245.08,510.32,1219.7,488.42,1219.7,453.34Z"
        fill={textColor}
      />
      <path
        className="cls-7"
        d="M1409.79,507.83l-37.08-123.66h27.87l20.65,70.16,5.73,31.85h2l9.2-34.33L1464,384.17h34.34l26.12,67.68,9.21,34.33h2l5.72-31.85,20.65-70.16h27.37l-37.57,123.66h-33.34l-27.12-71.16-9.46-30.36h-2l-9.45,30.36-27.37,71.16Z"
        fill={textColor}
      />
      <path
        className="cls-7"
        d="M1597.64,478c0-19.16,15.42-29.11,39.81-31.85l56.23-6v-7.21c0-20.41-9-27.62-32.6-27.62-22.89,0-34.08,7.46-34.08,25.62v1h-26.63v-1c0-28.11,23.39-49.26,62.7-49.26s57,21.4,57,51v75.14h-24.63V477h-1.74c-6.47,20.9-26.13,33.34-54.24,33.34C1613.81,510.32,1597.64,498.37,1597.64,478Zm49,12.94c28.12,0,47-10.45,47-34.84l-48.52,5.48c-13.94,1.74-20.65,5-20.65,14.68C1624.51,486.18,1631.22,490.91,1646.65,490.91Z"
        fill={textColor}
      />
      <path
        className="cls-7"
        d="M1743.93,550.13v-166h24.64V418.5h2.24c5-22.89,22.39-36.82,51.5-36.82,38.32,0,60,26.12,60,64.44s-21.15,64.2-59.72,64.2c-27.87,0-45.78-14.68-50.76-37.33h-1v77.14Zm69.42-64.2c26.63,0,42.05-10.2,42.05-39.81,0-29.86-15.67-39.81-41.55-39.81-27.62,0-43,12.94-43,39.56v2.24C1770.81,473.49,1787,485.93,1813.35,485.93Z"
        fill={textColor}
      />
    </Svg>
  );
};

export default React.memo(Logo, (prev, next) => prev.isDark === next.isDark);
