import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M1.5 8.62403C1.5 8.20928 1.836 7.87403 2.25 7.87403H19.6117L17.5312 6.20978C16.77 5.59478 17.6985 4.42928 18.4688 5.03828L22.2188 8.03828C22.4002 8.19878 22.5187 8.42903 22.4932 8.67878C22.4812 8.88878 22.365 9.06653 22.2188 9.20978L18.4688 12.2098C17.7038 12.8173 16.7662 11.657 17.5312 11.0375L19.611 9.37328H2.25C1.836 9.37403 1.5 9.03878 1.5 8.62403ZM21.75 14.624H4.38825L6.468 12.9598C7.23 12.3463 6.30075 11.1763 5.53125 11.7883L1.78125 14.7883C1.635 14.9315 1.518 15.1085 1.50675 15.3193C1.4805 15.569 1.6005 15.8 1.78125 15.9605L5.53125 18.9605C6.2955 19.568 7.23375 18.407 6.468 17.7883L4.3875 16.124H21.75C22.7295 16.1203 22.7318 14.6278 21.75 14.624Z" />
    </Svg>
  );
};

export default Icon;
