import React from "react";
import Link from "./Link";
import { LinkProps } from "./types";
import OpenNewIcon from "../Svg/Icons/OpenNew";

const LinkExternal: React.FC<LinkProps> = ({ children, iconSize = "14px", ...props }) => {
  return (
    <Link external {...props}>
      {children}
      <OpenNewIcon
        style={{ width: iconSize, height: iconSize }}
        color={props.color ? props.color : "primary"}
        ml="8px"
      />
    </Link>
  );
};

export default LinkExternal;
