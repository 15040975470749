import { ChainId } from '@magicswap/sdk'
import { CHAIN_ID, CURRENT_CHAIN_ID } from './networks'
import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'Magic',
    lpAddresses: {
      97: '0x0B4a7d7e6b68E9eE6a690aE3d4C713ca96672b07',
      56: '0xb212420BbA87Fa23D773b46F7B57E828958fD186', // INTEGRATION: Replace with MagicSwap TOKEN ADDRESS
    },
    token: serializedTokens.syrup,
    quoteToken: serializedTokens.wbnb,
  },
  // INTEGRATION: Perform following steps for:
  // 1. Using Factory Contract create pairs in the following sequence: (IMPORTANT TO KEEP SEQUENCE!!!!)
  //    a. USDT <> BNB
  //    b. MAGIC (MagicSwap) <> BNB
  //    c. BUSD <> BNB
  //  !!! Save addresses for each created Pair !!!
  // 2. Using MasterChef Contract register LP addresses using 'add' method: (IMPORTANT TO KEEP SEQUENCE!!!!)
  //    a. USDT <> BNB LP
  //    b. MAGIC (MagicSwap) <> BNB LP
  //    c. BUSD <> BNB LP
  //  !!! MAKE SURE THAT INDEX OF REGISTERED LP SAME AS PID IN THE LIST BELOW !!!
  // 3. Add LP addresses to the list below
  //  - PID 2 = MAGIC (MagicSwap) <> BNB LP ADDRESS
  //  - PID 3 = BUSD <> BNB LP ADDRESS
  {
    pid: CURRENT_CHAIN_ID === ChainId.MAINNET ? 1 : 2,
    lpSymbol: 'MAGIC-BNB LP',
    lpAddresses: {
      97: '0x4A62280d1dF1206c22b9171054727E393E707c28',
      56: '0x8326989BceAde3b6a532A91089bBA8428A57eb9c', // INTEGRATION: Add MAINNET
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: CURRENT_CHAIN_ID === ChainId.MAINNET ? 2 : 3,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      97: '0xb561855b4CBf29850dA5F6F8B7efE37e062E86a1',
      56: '0xb3Fc34205379fD5cc1e9dF541C019Bf3F51CD228', // INTEGRATION: Add MAINNET
    },
    token: serializedTokens.busd,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: CURRENT_CHAIN_ID === ChainId.MAINNET ? 3 : 4,
    lpSymbol: 'USDT-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xbaA8AE5C9a486302B15375d133598713474Bd81E', // INTEGRATION: Add MAINNET
    },
    token: serializedTokens.usdt,
    quoteToken: serializedTokens.wbnb,
  },
].filter((f) => !!f.lpAddresses[CHAIN_ID])

export default farms
