import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" />
      <path
        d="M10.1005 17.6103C10.0865 17.6103 10.0727 17.61 10.0587 17.6094C9.75962 17.5971 9.48145 17.4526 9.29926 17.2151L6.20862 13.1857C5.86919 12.7432 5.95276 12.1093 6.39524 11.7699C6.83772 11.4305 7.47161 11.514 7.81104 11.9565L10.1656 15.0263L16.2412 8.33115C16.6159 7.91824 17.2545 7.88725 17.6674 8.26192C18.0804 8.63659 18.1113 9.27525 17.7367 9.68817L10.8482 17.2792C10.6564 17.4906 10.3847 17.6103 10.1005 17.6103Z"
        fill="white"
      />
    </Svg>
  );
};

export default Icon;
