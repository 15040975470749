import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path
        d="M12 0C5.3828 0 0 5.3828 0 12C0 18.6172 5.3828 24 12 24C18.6172 24 24 18.6172 24 12C24 5.3828 18.6172 0 12 0Z"
        fill="#2C89FF"
      />
      <path
        d="M16.2756 14.1199L12.9294 11.6102V6.49789C12.9294 5.98386 12.5139 5.56836 11.9998 5.56836C11.4858 5.56836 11.0703 5.98386 11.0703 6.49789V12.075C11.0703 12.3678 11.2079 12.6439 11.4421 12.8186L15.1602 15.6072C15.3275 15.7326 15.5227 15.7931 15.7169 15.7931C16.0004 15.7931 16.2793 15.6657 16.4615 15.4203C16.7702 15.0103 16.6865 14.4275 16.2756 14.1199Z"
        fill="white"
      />
    </Svg>
  );
};

export default Icon;
