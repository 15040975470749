import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M23.1477 3.31073C22.632 3.26372 22.176 3.64364 22.129 4.15927L22.0047 5.52188C19.8973 2.21222 16.239 0 12.0001 0C7.44006 0 3.42142 2.60259 1.42651 6.50878C1.19101 6.96989 1.37392 7.53459 1.83503 7.77009C2.29623 8.00559 2.86084 7.82273 3.09634 7.36158C4.78464 4.05577 8.17497 1.875 12.0001 1.875C15.4865 1.875 18.6329 3.69052 20.4354 6.53475L19.0987 5.58005C18.6773 5.27916 18.0918 5.37675 17.7909 5.79806C17.49 6.21942 17.5876 6.80489 18.0089 7.10583L22.0624 10.0009C22.6565 10.4244 23.476 10.0367 23.5409 9.32311L23.9961 4.32952C24.0432 3.81384 23.6633 3.35775 23.1477 3.31073Z" />
      <path d="M22.165 16.2301C21.7039 15.9946 21.1392 16.1775 20.9037 16.6386C19.2154 19.9444 15.8251 22.1252 11.9999 22.1252C8.51352 22.1252 5.36718 20.3097 3.56465 17.4654L4.90138 18.4201C5.32274 18.721 5.90821 18.6234 6.20915 18.2021C6.51004 17.7808 6.41245 17.1953 5.99113 16.8944L1.93762 13.9993C1.35079 13.5801 0.524761 13.9561 0.459136 14.6771L0.00393317 19.6707C-0.0430824 20.1863 0.336839 20.6424 0.852417 20.6894C1.3687 20.7363 1.82418 20.356 1.87115 19.8409L1.99537 18.4783C4.10278 21.788 7.76109 24.0002 11.9999 24.0002C16.56 24.0002 20.5786 21.3976 22.5735 17.4914C22.809 17.0303 22.6261 16.4656 22.165 16.2301Z" />
    </Svg>
  );
};

export default Icon;
