import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M13.2153 12L22.7483 2.46705C23.0839 2.13146 23.0839 1.58731 22.7483 1.25172C22.4127 0.916093 21.8685 0.916093 21.533 1.25172L12 10.7847L2.46705 1.25172C2.13142 0.916093 1.58731 0.916093 1.25172 1.25172C0.916093 1.58731 0.916093 2.13146 1.25172 2.46705L10.7847 12L1.25172 21.533C0.916093 21.8686 0.916093 22.4127 1.25172 22.7483C1.58731 23.0839 2.13142 23.0839 2.46705 22.7483L12 13.2153L21.533 22.7483C21.8685 23.0839 22.4127 23.0839 22.7483 22.7483C23.0839 22.4127 23.0839 21.8686 22.7483 21.533L13.2153 12Z" />
    </Svg>
  );
};

export default Icon;
