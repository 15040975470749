import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path
        d="M12 0C5.36705 0 0 5.36752 0 12C0 18.633 5.36752 24 12 24C18.633 24 24 18.6325 24 12C24 5.36705 18.6325 0 12 0Z"
        fill="#2C89FF"
      />
      <path
        d="M12 17.6328C12.5178 17.6328 12.9375 17.2131 12.9375 16.6953L12.9375 10.6581C12.9375 10.1404 12.5178 9.72064 12 9.72064C11.4822 9.72064 11.0625 10.1404 11.0625 10.6581L11.0625 16.6953C11.0625 17.2131 11.4822 17.6328 12 17.6328Z"
        fill="white"
      />
      <path
        d="M12 6.04102C11.301 6.04102 10.7344 6.60766 10.7344 7.30664C10.7344 8.00563 11.301 8.57227 12 8.57227C12.699 8.57227 13.2656 8.00563 13.2656 7.30664C13.2656 6.60766 12.699 6.04102 12 6.04102Z"
        fill="white"
      />
    </Svg>
  );
};

export default Icon;
