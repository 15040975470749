import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M21.635 4.25033C21.5057 2.58084 20.1069 1.26172 18.4048 1.26172H3.24103C1.45392 1.26172 0 2.71564 0 4.50275V19.4983C0 21.2854 1.45392 22.7393 3.24103 22.7393H20.759C22.5461 22.7393 24 21.2854 24 19.4983V7.37004C24 5.88655 22.9977 4.63357 21.635 4.25033ZM3.24103 2.77578H18.4049C19.2288 2.77578 19.9193 3.3559 20.0907 4.12906H3.24103C2.6065 4.12906 2.01445 4.31307 1.51406 4.6295V4.50275C1.51406 3.5505 2.28878 2.77578 3.24103 2.77578ZM20.759 21.2252H3.24103C2.28878 21.2252 1.51406 20.4505 1.51406 19.4983V7.37004C1.51406 6.4178 2.28878 5.64307 3.24103 5.64307H20.759C21.7113 5.64307 22.486 6.4178 22.486 7.37004V10.012H17.6587C15.7458 10.012 14.1895 11.5683 14.1895 13.4812C14.1895 15.3941 15.7458 16.9504 17.6587 16.9504H22.4859V19.4983C22.4859 20.4505 21.7112 21.2252 20.759 21.2252ZM22.4859 15.4364H17.6587C16.5806 15.4364 15.7036 14.5593 15.7036 13.4812C15.7036 12.4031 16.5806 11.526 17.6587 11.526H22.4859V15.4364Z" />
      <path d="M17.9395 14.3263C18.3748 14.3263 18.7277 13.9735 18.7277 13.5382C18.7277 13.1029 18.3748 12.75 17.9395 12.75C17.5042 12.75 17.1514 13.1029 17.1514 13.5382C17.1514 13.9735 17.5042 14.3263 17.9395 14.3263Z" />
    </Svg>
  );
};

export default Icon;
