import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path
        d="M12 0C5.36705 0 0 5.36752 0 12C0 18.633 5.36752 24 12 24C18.633 24 24 18.6325 24 12C24 5.36705 18.6325 0 12 0Z"
        fill="#FFA800"
      />
      <path
        d="M12 6.04102C11.4822 6.04102 11.0625 6.46073 11.0625 6.97852V13.0157C11.0625 13.5335 11.4822 13.9532 12 13.9532C12.5178 13.9532 12.9375 13.5335 12.9375 13.0157V6.97852C12.9375 6.46073 12.5178 6.04102 12 6.04102Z"
        fill="white"
      />
      <path
        d="M12 17.6328C12.699 17.6328 13.2656 17.0662 13.2656 16.3672C13.2656 15.6682 12.699 15.1016 12 15.1016C11.301 15.1016 10.7344 15.6682 10.7344 16.3672C10.7344 17.0662 11.301 17.6328 12 17.6328Z"
        fill="white"
      />
    </Svg>
  );
};

export default Icon;
