import React from "react";
import styled from "styled-components";
import Flex from "../../components/Box/Flex";
import { Box } from "../../components/Box";
import { ArrowBackIcon, CloseIcon } from "../../components/Svg";
import { ButtonProps, IconButton } from "../../components/Button";
import { ModalProps } from "./types";

export const ModalHeader = styled.div<{ background?: string }>`
  align-items: center;
  background: ${({ background }) => background || "transparent"};
  display: flex;
  padding: 24px;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 40px 26px 30px 40px;
  }
`;

export const ModalTitle = styled(Flex)`
  align-items: center;
  flex: 1;
`;

export const ModalBody = styled(Flex)`
  flex-direction: column;
  max-height: 80vh;
  overflow-y: ${({ overflowY }) => overflowY || "auto"};
`;

export const CloseButton = styled(IconButton)<ButtonProps>`
  height: auto;
`;

export const ModalCloseButton: React.FC<{ onDismiss: ModalProps["onDismiss"] }> = ({ onDismiss }) => {
  return (
    <CloseButton variant="text" onClick={onDismiss} aria-label="Close the dialog">
      <CloseIcon color="textSubtle" width={16} />
    </CloseButton>
  );
};

export const ModalBackButton: React.FC<{ onBack: ModalProps["onBack"] }> = ({ onBack }) => {
  return (
    <IconButton variant="text" height="auto" onClick={onBack} area-label="go back" mr={["16px", null, "28px"]}>
      <ArrowBackIcon color="textSubtle" width="29px" />
    </IconButton>
  );
};

export const ModalContainer = styled(Box)<{ minWidth: string }>`
  overflow: hidden;
  background: ${({ theme }) => theme.modal.background};
  box-shadow: ${({ theme }) => theme.shadows.default};
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  border-radius: 4px;
  width: 100%;
  max-width: 628px;
  height: auto;
  max-height: 100vh;
  z-index: ${({ theme }) => theme.zIndices.modal};

  ${({ theme }) => theme.mediaQueries.sm} {
    width: ${({ width }) => width || "100%"};
  }

  ${({ theme }) => theme.mediaQueries.xs} {
    min-width: ${({ minWidth }) => minWidth};
  }
`;
