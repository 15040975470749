import { useMemo } from 'react'
import { Flex, Button, Text } from '@pancakeswap/uikit'
import QuestionHelper from 'components/QuestionHelper'
import { useTranslation } from 'contexts/Localization'
import { useGasPriceManager } from 'state/user/hooks'
import { GAS_PRICE_GWEI, GAS_PRICE } from 'state/types'

const GasSettings = () => {
  const { t } = useTranslation()
  const [gasPrice, setGasPrice] = useGasPriceManager()
  const isTestNet = useMemo(() => gasPrice === GAS_PRICE_GWEI.testnet, [gasPrice])
  return (
    <Flex flexDirection="column">
      <Flex alignItems="center" height="16px">
        <Text lineHeight="16px">{t('Default Transaction Speed (GWEI)')}</Text>
        <QuestionHelper
          text={t(
            'Adjusts the gas price (transaction fee) for your transaction. Higher GWEI = higher speed = higher fees',
          )}
          placement="top-start"
          ml="4px"
        />
      </Flex>
      <Flex flexWrap="wrap">
        <Button
          mt="16px"
          mr="16px"
          padding="0 18.5px"
          scale="sm"
          disabled={isTestNet}
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.default)
          }}
          variant={gasPrice === GAS_PRICE_GWEI.default ? 'primary' : 'primaryLight'}
        >
          {t('Standard (%gasPrice%)', { gasPrice: GAS_PRICE.default })}
        </Button>
        <Button
          mt="16px"
          mr="16px"
          padding="0 18.5px"
          scale="sm"
          disabled={isTestNet}
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.fast)
          }}
          variant={gasPrice === GAS_PRICE_GWEI.fast ? 'primary' : 'primaryLight'}
        >
          {t('Fast (%gasPrice%)', { gasPrice: GAS_PRICE.fast })}
        </Button>
        <Button
          mt="16px"
          scale="sm"
          mr={`${isTestNet ? 16 : 0}px`}
          padding="0 18.5px"
          disabled={isTestNet}
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.instant)
          }}
          variant={gasPrice === GAS_PRICE_GWEI.instant ? 'primary' : 'primaryLight'}
        >
          {t('Instant (%gasPrice%)', { gasPrice: GAS_PRICE.instant })}
        </Button>
        {isTestNet && (
          <Button mt="16px" scale="sm" padding="0 18.5px" variant="primary">
            {t('Testnet (%gasPrice%)', { gasPrice: GAS_PRICE.testnet })}
          </Button>
        )}
      </Flex>
    </Flex>
  )
}

export default GasSettings
