import throttle from "lodash/throttle";
import React, { useEffect, useRef, useState } from "react";
import styled, { DefaultTheme } from "styled-components";
import BottomNav from "../../components/BottomNav";
import { Box } from "../../components/Box";
import Flex from "../../components/Box/Flex";
import Footer from "../../components/Footer";
import MenuItems from "../../components/MenuItems/MenuItems";
import { SubMenuItems } from "../../components/SubMenuItems";
import { useMatchBreakpoints } from "../../hooks";
import Logo from "./components/Logo";
import { MENU_HEIGHT, MOBILE_MENU_HEIGHT, TOP_BANNER_HEIGHT, TOP_BANNER_HEIGHT_MOBILE } from "./config";
import { NavProps } from "./types";
import { MenuContext } from "./context";
import { LangSelector } from "../../components/LangSelector";

const getMenuBackground = (theme: DefaultTheme, isLandingPage: boolean, currentOffset: number): string => {
  if (isLandingPage) {
    return currentOffset < 100 ? "transparent" : theme.nav.background;
  }
  return theme.nav.background;
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledNav = styled.nav<{ isLandingPage: boolean; currentOffset: number }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${MENU_HEIGHT}px;
  background-color: ${({ theme, isLandingPage, currentOffset }) =>
    getMenuBackground(theme, isLandingPage, currentOffset)};
  border-bottom: 1px solid ${({ theme, isLandingPage }) => (isLandingPage ? "transparent" : theme.colors.cardBorder)};
  transform: translate3d(0, 0, 0);
  transition: background-color 0.2s ease;
  padding-left: 16px;
  padding-right: 16px;

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-left: 80px;
    padding-right: 80px;
  }
`;

const FixedContainer = styled.div<{ showMenu: boolean; height: number }>`
  position: fixed;
  top: ${({ showMenu, height }) => (showMenu ? 0 : `-${height}px`)};
  left: 0;
  transition: top 0.2s ease;
  height: ${({ height }) => `${height}px`};
  width: 100%;
  z-index: 20;
`;

const TopBannerContainer = styled.div<{ height: number }>`
  height: ${({ height }) => `${height}px`};
  min-height: ${({ height }) => `${height}px`};
  max-height: ${({ height }) => `${height}px`};
  width: 100%;
`;

const BodyWrapper = styled(Box)`
  position: relative;
  display: flex;
`;

const Inner = styled.div<{ isPushed: boolean; showMenu: boolean }>`
  flex-grow: 1;
  transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  max-width: 100%;
`;

const SubMenu = styled(SubMenuItems)<{ isVisible: boolean }>`
  justify-content: flex-start;
  background-color: transparent;
  border-bottom: ${({ isVisible }) => (isVisible ? "1px solid" : "none")};
  border-color: ${({ theme }) => theme.colors.inputSecondary};
  padding-top: ${({ isVisible }) => (isVisible ? 40 : 0)}px;
  margin-left: 16px;
  margin-right: 16px;
  text-transform: uppercase;

  ${({ theme }) => theme.mediaQueries.lg} {
    margin-left: 80px;
    margin-right: 80px;
  }
`;

const Menu: React.FC<NavProps> = ({
  linkComponent = "a",
  userMenu,
  banner,
  globalMenu,
  isDark,
  toggleTheme,
  currentLang,
  setLang,
  cakePriceUsd,
  links,
  subLinks,
  background,
  footerLinks,
  activeItem,
  activeSubItem,
  langs,
  buyCakeLabel,
  children,
  isLandingPage = false,
  t,
}) => {
  const { isMobile } = useMatchBreakpoints();
  const [showMenu, setShowMenu] = useState(true);
  const [currentOffset, setCurrentOffset] = useState(0);

  const refPrevOffset = useRef(typeof window === "undefined" ? 0 : window.pageYOffset);

  const topBannerHeight = isMobile ? TOP_BANNER_HEIGHT_MOBILE : TOP_BANNER_HEIGHT;

  const totalTopMenuHeight = banner ? MENU_HEIGHT + topBannerHeight : MENU_HEIGHT;

  useEffect(() => {
    const handleScroll = () => {
      const { pageYOffset } = window;
      setCurrentOffset(pageYOffset);
      // const isBottomOfPage = window.document.body.clientHeight === pageYOffset + window.innerHeight;
      // const isTopOfPage = pageYOffset === 0;
      // // Always show the menu when user reach the top
      // if (isTopOfPage) {
      //   setShowMenu(true);
      // }
      // // Avoid triggering anything at the bottom because of layout shift
      // else if (!isBottomOfPage) {
      //   if (pageYOffset < refPrevOffset.current || pageYOffset <= totalTopMenuHeight) {
      //     // Has scroll up
      //     setShowMenu(true);
      //   } else {
      //     // Has scroll down
      //     setShowMenu(false);
      //   }
      // }
      refPrevOffset.current = pageYOffset;
    };
    const throttledHandleScroll = throttle(handleScroll, 100);

    window.addEventListener("scroll", throttledHandleScroll);
    return () => {
      window.removeEventListener("scroll", throttledHandleScroll);
    };
  }, [totalTopMenuHeight]);

  // Find the home link if provided
  const homeLink = links.find((link) => link.label === "Home");

  const subLinksWithoutMobile = subLinks?.filter((subLink) => !subLink.isMobileOnly);
  const subLinksMobileOnly = subLinks?.filter((subLink) => subLink.isMobileOnly);

  return (
    <MenuContext.Provider value={{ linkComponent }}>
      <Wrapper>
        <FixedContainer showMenu={showMenu} height={totalTopMenuHeight}>
          {banner && <TopBannerContainer height={topBannerHeight}>{banner}</TopBannerContainer>}
          <StyledNav isLandingPage={isLandingPage} currentOffset={currentOffset}>
            <Flex alignItems="center">
              <Logo isDark={isDark} href={homeLink?.href ?? "/"} />
              {!isMobile && (
                <MenuItems t={t} items={links} activeItem={activeItem} activeSubItem={activeSubItem} ml="64px" />
              )}
            </Flex>
            <Flex alignItems="center">
              <Box mr={["8px", "8px", "8px", "32px"]}>
                <LangSelector currentLang={currentLang} langs={langs} setLang={setLang} />
              </Box>
              {globalMenu} {userMenu}
            </Flex>
          </StyledNav>
        </FixedContainer>
        {subLinks && (
          <Flex style={{ background }} justifyContent="space-around">
            <SubMenu
              isVisible={!!subLinks.length}
              items={subLinksWithoutMobile}
              mt={`${totalTopMenuHeight}px`}
              activeItem={activeSubItem}
              t={t}
            />

            {subLinksMobileOnly?.length > 0 && (
              <SubMenuItems
                t={t}
                items={subLinksMobileOnly}
                mt={`${totalTopMenuHeight}px`}
                activeItem={activeSubItem}
                isMobileOnly
              />
            )}
          </Flex>
        )}
        <BodyWrapper mt={isLandingPage ? "0" : !subLinks ? `${totalTopMenuHeight}px` : "0"}>
          <Inner isPushed={false} showMenu={showMenu}>
            {children}
            <Footer
              href={homeLink?.href ?? "/"}
              items={footerLinks}
              isDark={isDark}
              toggleTheme={toggleTheme}
              langs={langs}
              setLang={setLang}
              currentLang={currentLang}
              cakePriceUsd={cakePriceUsd}
              buyCakeLabel={buyCakeLabel}
              mb={[`${MOBILE_MENU_HEIGHT}px`, null, "0px"]}
            />
          </Inner>
        </BodyWrapper>
        {isMobile && <BottomNav t={t} items={links} activeItem={activeItem} activeSubItem={activeSubItem} />}
      </Wrapper>
    </MenuContext.Provider>
  );
};

export default Menu;
