import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M19.248 0H9.16502C7.70039 0 6.50877 1.19162 6.50877 2.65625V2.98225H4.75195C3.28733 2.98225 2.0957 4.17387 2.0957 5.6385V21.3439C2.0957 22.8085 3.28733 24.0001 4.75195 24.0001H14.8349C16.2996 24.0001 17.4911 22.8085 17.4911 21.3439V21.0179H19.2479C20.7125 21.0179 21.9041 19.8262 21.9041 18.3616V2.65625C21.9042 1.19162 20.7126 0 19.248 0ZM15.7778 21.3439C15.7778 21.8637 15.3548 22.2867 14.835 22.2867H4.75195C4.2321 22.2867 3.80912 21.8637 3.80912 21.3439V5.63838C3.80912 5.11853 4.2321 4.69555 4.75195 4.69555H14.8349C15.3548 4.69555 15.7776 5.11853 15.7776 5.63838V21.3439H15.7778ZM20.1908 18.3616C20.1908 18.8815 19.7678 19.3044 19.248 19.3044H17.4912V5.63838C17.4912 4.17376 16.2996 2.98214 14.835 2.98214H8.22218V2.65613C8.22218 2.13628 8.64517 1.7133 9.16502 1.7133H19.248C19.7678 1.7133 20.1908 2.13628 20.1908 2.65613V18.3616Z" />
    </Svg>
  );
};

export default Icon;
