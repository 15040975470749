import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M16.8481 5.68073C17.3619 5.68073 17.7783 5.26102 17.7783 4.74323V2.31459C17.7783 1.03833 16.7367 0 15.4563 0H4.32201C3.04168 0 2 1.03833 2 2.31459V21.6854C2 22.9617 3.04168 24 4.32201 24H15.4563C16.7367 24 17.7783 22.9617 17.7783 21.6854V19.2568C17.7783 18.739 17.3618 18.3193 16.8481 18.3193C16.3343 18.3193 15.9179 18.739 15.9179 19.2568V21.6855C15.9179 21.9278 15.7108 22.125 15.4563 22.125H4.32201C4.0675 22.125 3.86047 21.9278 3.86047 21.6855V2.31459C3.86047 2.0722 4.0675 1.875 4.32201 1.875H15.4563C15.7108 1.875 15.9179 2.0722 15.9179 2.31459V4.74323C15.9179 5.26102 16.3343 5.68073 16.8481 5.68073Z" />
      <path d="M21.7194 11.3293L16.7685 6.46363C16.4007 6.10208 15.8118 6.10953 15.4531 6.48027C15.0944 6.85091 15.1018 7.44448 15.4697 7.80594L18.7836 11.063H9.88922C9.37545 11.063 8.95898 11.4827 8.95898 12.0005C8.95898 12.5182 9.37545 12.938 9.88922 12.938H18.7836L15.4697 16.1949C15.1018 16.5564 15.0944 17.15 15.4531 17.5206C15.8117 17.8912 16.4007 17.8989 16.7685 17.5372L21.7194 12.6716C22.096 12.3021 22.0915 11.6943 21.7194 11.3293Z" />
    </Svg>
  );
};

export default Icon;
