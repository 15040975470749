import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 8 4" {...props}>
      <path d="M8 0H0L4 4L8 0Z" />
    </Svg>
  );
};

export default Icon;
