import styled from "styled-components";
import { Flex } from "../Box";
import { ArrowDropDownIcon } from "../Svg";

export const Menu = styled.div<{ isOpen: boolean }>`
  border-radius: ${({ theme }) => theme.radii.small};
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.default};
  pointer-events: auto;
  width: 216px;
  max-height: 264px;
  overflow-y: auto;
  visibility: visible;
  z-index: 1001;

  ${({ isOpen }) =>
    !isOpen &&
    `
    pointer-events: none;
    visibility: hidden;
  `}
`;

export const MenuItem = styled.button<any>`
  align-items: center;
  border: 0;
  background: transparent;
  color: ${({ theme, disabled }) => theme.colors[disabled ? "textDisabled" : "text"]};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  min-height: 48px;
  justify-content: space-between;
  outline: 0;
  padding: 12px 24px;
  width: 100%;

  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.tertiary};
  }

  &:active:not(:disabled) {
    opacity: 0.85;
    transform: translateY(1px);
  }
`;

export const Lang = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  padding: 4px 10px 4px;
  font-size: 12px;
  align-items: center;
  transition: background-color 0.2s, opacity 0.2s, border 0.2s, color 0.2s;
`;

export const ArrowIcon = styled(ArrowDropDownIcon)`
  align-items: center;
  margin-left: 4px;
  width: 8px;
`;

export const Selector = styled(Flex)`
  align-items: center;
  height: 56px;
  cursor: pointer;
  display: inline-flex;
  position: relative;

  &:hover ${Lang}, &:focus-within ${Menu} {
    border: 1px solid ${({ theme }) => theme.colors.textSubtle};
    color: ${({ theme }) => theme.colors.textSubtle};
  }
`;
