import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M12 0C5.38324 0 0 5.38324 0 12C0 18.6168 5.38324 24 12 24C18.6168 24 24 18.6168 24 12C24 5.38324 18.6168 0 12 0ZM12 1.8C17.644 1.8 22.2 6.35604 22.2 12C22.2 17.644 17.644 22.2 12 22.2C6.35603 22.2 1.8 17.644 1.8 12C1.8 6.35604 6.35603 1.8 12 1.8ZM16.4824 8.3918C16.2487 8.39861 16.0268 8.49611 15.8637 8.66367L10.5 14.0273L8.13633 11.6637C8.0534 11.5773 7.95407 11.5083 7.84415 11.4608C7.73424 11.4133 7.61595 11.3882 7.49621 11.387C7.37648 11.3858 7.2577 11.4085 7.14684 11.4537C7.03598 11.499 6.93527 11.5659 6.8506 11.6506C6.76593 11.7353 6.69901 11.836 6.65375 11.9468C6.60849 12.0577 6.5858 12.1765 6.58702 12.2962C6.58823 12.4159 6.61333 12.5342 6.66084 12.6442C6.70834 12.7541 6.7773 12.8534 6.86367 12.9363L9.86367 15.9363C10.0325 16.105 10.2613 16.1998 10.5 16.1998C10.7387 16.1998 10.9675 16.105 11.1363 15.9363L17.1363 9.93633C17.2662 9.80988 17.3548 9.64717 17.3907 9.46951C17.4265 9.29184 17.4079 9.1075 17.3372 8.9406C17.2665 8.7737 17.1471 8.63202 16.9946 8.5341C16.8421 8.43618 16.6636 8.38658 16.4824 8.3918Z" />
    </Svg>
  );
};

export default Icon;
