import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M23.7414 20.4843L14.1726 1.34297C13.7585 0.514594 12.926 0 12 0C11.074 0 10.2415 0.514594 9.82734 1.34297L0.258652 20.4843C-0.120614 21.2429 -0.0808167 22.1265 0.365058 22.848C0.810886 23.5693 1.58329 24 2.43121 24H21.5688C22.4167 24 23.1891 23.5694 23.6349 22.848C24.0808 22.1265 24.1206 21.2429 23.7414 20.4843ZM22.04 21.8622C21.9912 21.9412 21.8474 22.125 21.5687 22.125H2.43121C2.1525 22.125 2.00882 21.9412 1.95998 21.8622C1.91114 21.7831 1.81092 21.5723 1.9357 21.3227L11.5044 2.18138C11.6427 1.90486 11.8971 1.875 12 1.875C12.1029 1.875 12.3573 1.90486 12.4956 2.18138L22.0643 21.3227C22.189 21.5723 22.0888 21.7831 22.04 21.8622Z" />
      <path d="M12 8.44141C11.4822 8.44141 11.0625 8.86113 11.0625 9.37891V15.2908C11.0625 15.8086 11.4822 16.2283 12 16.2283C12.5178 16.2283 12.9375 15.8086 12.9375 15.2908V9.37891C12.9375 8.86113 12.5178 8.44141 12 8.44141Z" />
      <path d="M11.9995 19.8379C12.6985 19.8379 13.2651 19.2713 13.2651 18.5723C13.2651 17.8733 12.6985 17.3066 11.9995 17.3066C11.3005 17.3066 10.7339 17.8733 10.7339 18.5723C10.7339 19.2713 11.3005 19.8379 11.9995 19.8379Z" />
    </Svg>
  );
};

export default Icon;
