import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M12 0C5.3828 0 0 5.3828 0 12C0 18.6172 5.3828 24 12 24C18.6172 24 24 18.6172 24 12C24 5.3828 18.6172 0 12 0ZM12 22.141C6.40898 22.141 1.85902 17.591 1.85902 12C1.85902 6.40898 6.40898 1.85902 12 1.85902C17.592 1.85902 22.141 6.40898 22.141 12C22.141 17.591 17.591 22.141 12 22.141Z" />
      <path d="M22.125 17.8465C22.4011 17.3682 21.5509 16.3604 20.2259 15.5954L4.77658 6.67568C3.45156 5.91068 2.15363 5.67828 1.87748 6.15659C1.60133 6.63489 2.45155 7.64273 3.77657 8.40773L19.2259 17.3274C20.5509 18.0924 21.8488 18.3248 22.125 17.8465Z" />
    </Svg>
  );
};

export default Icon;
