import React from "react";
import styled from "styled-components";
import { lightColors } from "../../../theme";
import { FlexProps } from "../../Box";
import Flex from "../../Box/Flex";
import Dropdown from "../../Dropdown/Dropdown";
import Link from "../../Link/Link";
import Text from "../../Text/Text";
import { socials } from "../config";

const SocialLink = styled(Link)`
  opacity: 0.8;
`;

const SocialLinks: React.FC<FlexProps> = ({ ...props }) => (
  <Flex {...props}>
    {socials.map((social, index) => {
      const iconProps = {
        width: "20px",
        color: lightColors.white,
        style: { cursor: "pointer", opacity: "0.8" },
      };
      const Icon = social.icon;
      const mr = index < socials.length - 1 ? "24px" : 0;
      if (social.items) {
        return (
          <Dropdown key={social.label} position="top" target={<Icon {...iconProps} mr={mr} />}>
            {social.items.map((item) =>
              item.href ? (
                <Link external key={item.label} href={item.href} aria-label={item.label} color="text">
                  {item.label}
                </Link>
              ) : (
                <Text small bold>
                  {item.label}
                </Text>
              )
            )}
          </Dropdown>
        );
      }
      return (
        <SocialLink
          as={social.href ? SocialLink : Text}
          external
          key={social.label}
          href={social.href}
          aria-label={social.label}
          mr={mr}
        >
          <Icon {...iconProps} />
        </SocialLink>
      );
    })}
  </Flex>
);

export default React.memo(SocialLinks, () => true);
