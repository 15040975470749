import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 23 32" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.1802 15.1342C22.0533 14.9943 21.8801 14.96 21.705 14.9219C20.1321 14.5808 18.6538 13.9956 17.2319 13.2586C17.0233 13.1544 16.863 12.9756 16.784 12.7589C16.664 12.4597 16.5752 12.1574 16.4864 11.8844V11.8801C16.3874 11.574 16.2859 11.3061 16.1412 11.1249C15.3135 9.31524 14.4852 7.50536 13.6566 5.69526C13.6566 5.69526 12.9048 3.8427 11.0146 0.398645C10.752 -0.0791754 10.384 -0.129768 10.0046 0.259359C9.10486 1.18252 8.20813 2.10818 7.31436 3.03634L6.8201 3.54851L6.86261 3.61097L9.13214 2.75964C9.11438 2.82772 9.07567 2.97013 9.02555 3.14814C8.5903 4.69528 6.68876 10.9313 6.43243 11.6964C6.39334 11.7918 6.39334 11.8984 6.43243 11.9937C6.49049 12.0874 6.58374 12.1547 6.69193 12.1811C10.2977 13.316 11.1263 13.3747 11.1333 13.3747C12.1586 13.5121 13.1846 13.6052 14.2207 13.5534C14.7887 13.5151 15.3542 13.4467 15.9147 13.3485C15.883 13.4016 15.8767 13.4291 15.8608 13.4366C15.7811 13.4747 15.6993 13.5084 15.6159 13.5378C12.4435 14.5846 9.30853 14.5003 6.20909 13.2629C5.99845 13.1792 5.82841 13.1624 5.61205 13.2479C4.70665 13.6083 3.78982 13.9412 2.85714 14.2286C2.10657 14.4666 1.34158 14.6581 0.566668 14.8019C0.245621 14.8588 0.0406843 15.0281 0.00388453 15.3572C-0.0310118 15.6695 0.173925 15.8063 0.428986 15.905C0.52162 15.9412 0.611081 15.9856 0.703716 16.0224C6.07564 18.1586 11.5982 18.6507 17.2712 17.499C18.8703 17.1697 20.4304 16.678 21.9264 16.0318C22.3458 15.8525 22.4575 15.439 22.1802 15.1342Z"
        fill="url(#paint0_linear_2199_9547)"
      />
      <path
        d="M12.0443 28.723H12.0355C12.0355 27.7861 12.0037 26.8523 12.0463 25.9192C12.0767 25.2471 11.6789 25.2359 11.1941 25.2459C10.7094 25.2559 10.264 25.1872 10.2818 25.9211C10.3211 27.5506 10.2926 29.1827 10.2926 30.8136C10.2926 32.0584 10.2926 32.0484 11.5615 31.9722C11.9162 31.951 12.0589 31.8329 12.0507 31.4675C12.0304 30.5556 12.0443 29.6381 12.0443 28.723Z"
        fill="url(#paint1_linear_2199_9547)"
      />
      <path
        d="M3.52051 22.9773V27.8885C3.77469 27.8886 4.0264 27.8394 4.26126 27.7437C4.49612 27.648 4.70952 27.5077 4.88928 27.3308C5.06905 27.1539 5.21165 26.9438 5.30894 26.7127C5.40623 26.4815 5.4563 26.2337 5.4563 25.9835V21.0723C4.94301 21.0723 4.45073 21.273 4.08771 21.6302C3.7247 21.9874 3.52068 22.472 3.52051 22.9773Z"
        fill="url(#paint2_linear_2199_9547)"
      />
      <path
        d="M6.8584 25.4802V30.3921C7.3718 30.3921 7.86418 30.1913 8.22721 29.8339C8.59025 29.4766 8.7942 28.9918 8.7942 28.4864V23.5752C8.2809 23.5752 7.78862 23.7759 7.4256 24.1331C7.06259 24.4904 6.85857 24.9749 6.8584 25.4802Z"
        fill="url(#paint3_linear_2199_9547)"
      />
      <path
        d="M16.9307 21.0723V25.9835C16.9307 26.4888 17.1345 26.9733 17.4975 27.3306C17.8604 27.6878 18.3526 27.8885 18.8658 27.8885V22.9773C18.8658 22.4721 18.6619 21.9875 18.299 21.6302C17.9361 21.273 17.4439 21.0723 16.9307 21.0723Z"
        fill="url(#paint4_linear_2199_9547)"
      />
      <path
        d="M13.5918 23.5752V28.4864C13.5917 28.7367 13.6417 28.9845 13.7389 29.2157C13.8361 29.4469 13.9787 29.6569 14.1584 29.8339C14.3381 30.0109 14.5514 30.1512 14.7863 30.247C15.0211 30.3428 15.2728 30.3921 15.527 30.3921V25.4802C15.5268 24.975 15.3229 24.4906 14.96 24.1333C14.5971 23.7761 14.105 23.5754 13.5918 23.5752Z"
        fill="url(#paint5_linear_2199_9547)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2199_9547"
          x1="18.2414"
          y1="32.7068"
          x2="8.08263"
          y2="7.15356"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.17" stopColor="#2C89FF" />
          <stop offset="1" stopColor="#70FEDC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2199_9547"
          x1="13.6026"
          y1="34.5518"
          x2="3.4439"
          y2="8.99921"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.17" stopColor="#2C89FF" />
          <stop offset="1" stopColor="#70FEDC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2199_9547"
          x1="9.31775"
          y1="36.2538"
          x2="-0.840987"
          y2="10.7006"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.17" stopColor="#2C89FF" />
          <stop offset="1" stopColor="#70FEDC" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2199_9547"
          x1="11.3061"
          y1="35.4651"
          x2="1.14674"
          y2="9.91188"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.17" stopColor="#2C89FF" />
          <stop offset="1" stopColor="#70FEDC" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2199_9547"
          x1="20.8473"
          y1="31.6705"
          x2="10.6886"
          y2="6.11729"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.17" stopColor="#2C89FF" />
          <stop offset="1" stopColor="#70FEDC" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2199_9547"
          x1="17.0948"
          y1="33.1641"
          x2="6.93602"
          y2="7.61086"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.17" stopColor="#2C89FF" />
          <stop offset="1" stopColor="#70FEDC" />
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default Icon;
