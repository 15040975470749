import React, { useContext } from "react";
import styled from "styled-components";
import { baseColors, lightColors } from "../../theme/colors";
import { MenuContext } from "../../widgets/Menu/context";
import { Flex, Grid } from "../Box";
import { Link } from "../Link";
import { LogoWithTextIcon } from "../Svg";
import { StyledFooter, StyledList, StyledListItem, StyledText, StyledSocialLinks } from "./styles";
import { FooterProps } from "./types";

const MenuItem: React.FC<FooterProps> = ({ href, isDark, items, ...props }) => {
  const { linkComponent } = useContext(MenuContext);
  const isAbsoluteUrl = href?.startsWith("http");
  const StyledLink = styled("a")`
    margin-bottom: 42px;
    display: flex;
    align-items: flex-start;
    width: 200px;
    height: 60px;
  `;
  return (
    <StyledFooter p={["32px 37px", null, "40px 80px 78px"]} {...props} justifyContent="center">
      <Flex flexDirection="column" width={["100%", null, "1200px;"]}>
        <Flex width="100%" flexDirection={["column-reverse", "column-reverse", "row"]} justifyContent="space-between">
          <Grid
            gridTemplateColumns={["repeat(2, 1fr)", null, "repeat(3, 1fr)"]}
            gridColumnGap={["50px", "100px", "20px", null, "120px", "160px"]}
            gridRowGap={["50px", null, null, null]}
            mb={["44px", "44px", "15px"]}
            mr={[null, "0px", "15px", "0px"]}
          >
            {items?.map((item) => (
              <StyledList key={item.label}>
                <StyledListItem>{item.label}</StyledListItem>
                {item.items?.map(({ label, href: itemHref, isHighlighted = false, target = "_self" }) => (
                  <StyledListItem key={label}>
                    {itemHref ? (
                      <Link
                        href={itemHref}
                        target={target}
                        rel="noreferrer noopener"
                        color={isHighlighted ? baseColors.warning : lightColors.white}
                        bold={false}
                      >
                        {label}
                      </Link>
                    ) : (
                      <StyledText>{label}</StyledText>
                    )}
                  </StyledListItem>
                ))}
              </StyledList>
            ))}
          </Grid>
          <Flex alignItems="flex-start">
            {isAbsoluteUrl ? (
              <StyledLink as="a" href={href} aria-label="Magic home page">
                <LogoWithTextIcon isDark={isDark} width="100%" id="footer-logo" />
              </StyledLink>
            ) : (
              <StyledLink as={linkComponent} href={href} aria-label="Magic home page">
                <LogoWithTextIcon isDark={isDark} width="100%" id="footer-logo" />
              </StyledLink>
            )}
          </Flex>
        </Flex>
        <StyledSocialLinks order={[2]} pb="34px" />
      </Flex>
    </StyledFooter>
  );
};

export default MenuItem;
