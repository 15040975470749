import React, { useEffect, useState } from "react";
import { usePopper } from "react-popper";
import { LangSelectorProps, Language } from "./types";
import { Selector, Lang, ArrowIcon, Menu, MenuItem } from "./styles";

const LangSelector: React.FC<LangSelectorProps> = ({ langs, currentLang, setLang, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null);
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(targetRef, tooltipRef, {
    strategy: "fixed",
    placement: "bottom-end",
    modifiers: [{ name: "offset", options: { offset: [0, 0] } }],
  });

  useEffect(() => {
    const showDropdownMenu = () => {
      setIsOpen(true);
    };

    const hideDropdownMenu = (evt: MouseEvent | TouchEvent) => {
      const target = evt.target as Node;
      if (target && !tooltipRef?.contains(target)) {
        setIsOpen(false);
        evt.stopPropagation();
      }
    };

    targetRef?.addEventListener("mouseenter", showDropdownMenu);
    targetRef?.addEventListener("mouseleave", hideDropdownMenu);

    return () => {
      targetRef?.removeEventListener("mouseenter", showDropdownMenu);
      targetRef?.removeEventListener("mouseleave", hideDropdownMenu);
    };
  }, [targetRef, tooltipRef, setIsOpen]);

  const onChangeLang = (lang: Language) => {
    setIsOpen(false);
    setLang(lang);
  };

  return (
    <Selector alignItems="center" height="100%" ref={setTargetRef} {...props}>
      <Lang
        onTouchStart={() => {
          setIsOpen((s) => !s);
        }}
      >
        {currentLang?.toUpperCase()}
        <ArrowIcon color="currentColor" width="16" />
      </Lang>
      <Menu style={styles.popper} ref={setTooltipRef} {...attributes.popper} isOpen={isOpen}>
        {langs.map((lang: Language) => (
          <MenuItem key={lang.locale} onClick={() => onChangeLang(lang)}>
            {lang.language}
          </MenuItem>
        ))}
      </Menu>
    </Selector>
  );
};

export default LangSelector;
