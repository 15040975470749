export type Position = "top" | "top-right" | "bottom";

export const variants = {
  TOP: "top",
  TOP_RIGHT: "top-right",
  BOTTOM: "bottom",
} as const;

export interface PositionProps {
  position?: Position;
}

export interface DropdownProps extends PositionProps {
  target: React.ReactElement;
}
